import React, { useState } from 'react';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:9000/api/messages', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage('Mesajınız başarıyla kaydedildi.');
        setFormData({ name: '', surname: '', email: '', phone: '', message: '' });
      } else {
        setResponseMessage('Mesaj gönderilirken bir hata oluştu.');
      }
    } catch (error) {
      setResponseMessage('Mesaj gönderilirken bir hata oluştu.');
    }
  };

  return (
    <div className="contact-page">
      <h1>BİZİMLE İLETİŞİME GEÇİN</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            placeholder=" " /* Placeholder kaldırıldı, yerine label kullanılıyor */
            required 
          />
          <label>İsminizi giriniz</label>
        </div>
        <div className="form-group">
          <input 
            type="text" 
            name="surname" 
            value={formData.surname} 
            onChange={handleChange} 
            placeholder=" "
            required 
          />
          <label>Soyisminizi giriniz</label>
        </div>
        <div className="form-group">
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder=" "
            required 
          />
          <label>Geçerli bir e-posta adresi giriniz</label>
        </div>
        <div className="form-group">
          <input 
            type="tel" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
            placeholder=" "
            required 
          />
          <label>Telefon numaranız (ör: XXX-XXX-XXXX)</label>
        </div>
        <div className="form-group full-width">
          <textarea 
            name="message" 
            value={formData.message} 
            onChange={handleChange}
            placeholder=" "
            required
          ></textarea>
          <label>Mesajınızı buraya yazın...</label>
        </div>
        <button type="submit" className="submit-btn">Gönder</button>
        {responseMessage && <p className="response-message">{responseMessage}</p>}
      </form>
    </div>
  );
}

export default Contact;
