import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  const ref = useRef()

  useLayoutEffect(() => {
    console.log(pathname)
    window.scroll(0, 0);
  }, [pathname]);

  return <div ref={ref}>
    {children}
  </div>;
}

export default ScrollToTop;
