import React from 'react';
import '../styles/hero.css';

function Hero() {
  return (
    <div className="hero">
      <h1>ACM IĞDIR'A HOŞ GELDİNİZ!</h1>
      <div className="neon-circle"></div>
      <div className="neon-circle"></div>
    </div>
  );
}

export default Hero;
