import React, { useEffect, useState } from 'react';
import '../styles/duyurular.css';



function Duyurular() {
  const [announcements, setAnnouncements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState(null);

  useEffect(() => {
    fetch('http://localhost:9000/api/duyurular')
      .then((response) => response.json())
      .then((data) => setAnnouncements(data))
      .catch((error) => console.error('Duyurular alınırken hata oluştu:', error));
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleCardClick = (announcement) => {
    setActiveAnnouncement(announcement);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveAnnouncement(null);
  };

  return (
    <div className="duyurular-page">
      <h1 className="duyurular-title">DUYURULAR</h1>

      <div className="duyurular-container">
        {announcements.length > 0 ? (
          announcements.map((announcement, index) => (
            <div key={index} className="duyuru-card" onClick={() => handleCardClick(announcement)}>
              <img src={`http://localhost:9000/assets/${announcement.cardImage}`} alt={announcement.title} className="duyuru-image" />
              <div className="duyuru-info">
                <h2>{announcement.title}</h2>
                <p>{announcement.shortDetail}</p>
                <p>{announcement.date}</p>
                <button>Detayları Gör</button>
              </div>
            </div>
          ))
        ) : (
          <p>Görüntülenecek duyuru yok.</p>
        )}
      </div>

      {showModal && activeAnnouncement && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <img src={`http://localhost:9000/assets/${activeAnnouncement.modalImage}`} alt={activeAnnouncement.title} />
            <h2>{activeAnnouncement.title}</h2>
            <p>{activeAnnouncement.modalDetail}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Duyurular;
