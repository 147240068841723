import React from 'react';
import { useEffect } from 'react';
import { useParams, Redirect } from "react-router-dom";
import '../styles/EtkinliklerPage.css'; // Stil dosyasını dahil et

function EtkinliklerPage() {
  const { slug } = useParams()
  useEffect(() => {
    window.scroll(0, 0)
  }, [slug])
  return (
    <div className="etkinlikler-page">
      <section className="section">
        <h2 className="etkinlikler-subtitle">ETKİNLİKLERİMİZ</h2>

        <h1 className="section-title">ACMen Talks</h1>
        <div className="divider"></div>
        <p>
          ACMen Talks, belirli bir tema etrafında toplanan katılımcıların hem İngilizce hem de Türkçe terimlerle etkileşime girdiği, özgün bir konuşma pratiği etkinliğidir. Etkinlik sırasında, tema ile ilgili kilit terimler ve kavramlar açıklanarak katılımcılara sunulur. Katılımcılar bu terimleri kullanarak, derinlemesine tartışmalar yapma fırsatı bulur. İyi derecede İngilizce bilen rehberler de bu sohbetlere dahil olarak, katılımcıların dil becerilerini geliştirirken hatalarını düzeltir ve onlara geri bildirimde bulunur.
        </p>
        <p>
          Bu etkinlik, özellikle İngilizce konuşma konusunda kendine güven kazanmak isteyenler için idealdir. Katılımcılar, hem bireysel hem de grup halinde konuşma pratiği yaparak İngilizce yetkinliklerini artırırken, konuşma sırasında yaptıkları hataların anında düzeltilmesi sayesinde dil öğrenim süreçlerini hızlandırırlar. Aynı zamanda bu etkinlik, dil öğrenme sürecindeki kaygıları azaltır ve daha rahat bir iletişim ortamı sağlar.
        </p>
        <p>
          ACMen Talks, katılımcılara sadece dil pratiği sunmakla kalmaz, aynı zamanda eğlenceli ve interaktif bir atmosferde öğrenme deneyimi yaşatır. Hem kişisel gelişiminize katkı sağlarken hem de sosyal çevrenizi genişletmek istiyorsanız, bu etkinlik tam size göre!
        </p>
      </section>

      <section className="section">
        <h1 className="section-title">Teknik Söyleşiler</h1>
        <div className="divider"></div>
        <p>
          Teknik Söyleşiler, teknolojiye ilgi duyan herkesin buluştuğu, sıcak ve samimi bir ortam sunuyor. Bu etkinliklerde, sektörün deneyimli profesyonelleri ve liderleriyle bir araya geliyoruz. Onların eşsiz hikayelerini, karşılaştıkları zorlukları ve edindikleri tecrübeleri dinleme fırsatı buluyoruz. Teknolojinin her alanında merak ettiğiniz soruları sorma ve yeni ufuklar keşfetme şansınız var.
        </p>
      </section>

      <section className="section">
        <h1 className="section-title">Tekno Bilgi Günleri</h1>
        <div className="divider"></div>
        <p>
          Tekno Bilgi Günleri, üyelerimizin teknoloji ve bilim alanında bilgi paylaşımında bulunduğu etkinliklerdir. Her hafta belirlenen bir tema üzerinde derinlemesine sunumlar ve tartışmalar yaparız. Katılımcılar, hem güncel gelişmeleri takip eder hem de yeni bilgiler edinirler. Bu etkinlik, teknolojiye merak duyan herkes için mükemmel bir öğrenme ve paylaşım platformudur.
        </p>
      </section>

      <section className="section">
        <h1 className="section-title">Sunum Günleri</h1>
        <div className="divider"></div>
        <p>
          Sunum Günleri, üyelerimizin ilgi duydukları konularda sunumlar yaparak bilgilerini paylaştıkları ve birlikte öğrendikleri bir etkinliktir. Bu platformda, katılımcılar hem yeni bilgiler edinir hem de sunum ve iletişim becerilerini geliştirirler. Farklı alanlarda ilgi çekici konular keşfetmek, fikir alışverişinde bulunmak ve topluluk önünde konuşma deneyimi kazanmak isteyen herkes için harika bir fırsat!
        </p>
      </section>

      <section className="section">
        <h1 className="section-title">Teknik Geziler</h1>
        <div className="divider"></div>
        <p>
          Teknik Geziler, teknoloji ve bilişim dünyasının kalbine yaptığımız keşif dolu yolculuklardır. Bu etkinliklerde, önde gelen teknoloji şirketlerini, araştırma merkezlerini ve inovasyon odaklı kuruluşları ziyaret ediyoruz. Katılımcılar, sektörün nasıl işlediğini yakından görme, profesyonellerle tanışma ve ilham verici deneyimler yaşama fırsatı buluyor. Gerçek dünya uygulamalarını yerinde görerek, teorik bilgileri pratiğe dönüştürdüğümüz bir etkinlik.
        </p>
      </section>

      <section className="section">
        <h1 className="section-title">Fundays</h1>
        <div className="divider"></div>
        <p>
          Fundays, eğlence ve rekabetin bir araya geldiği özel bir etkinliktir. Bu günlerde, farklı temalarda düzenlenen oyunlar, yarışmalar ve aktivitelerle hem keyifli vakit geçirebilir hem de yeni arkadaşlıklar edinebilirsiniz. Strateji oyunlarından bilgi yarışmalarına, takım etkinliklerinden sürpriz aktivitelere kadar her şey sizi bekliyor. Eğlenmek, rekabetin heyecanını yaşamak ve unutulmaz anılar biriktirmek istiyorsanız sizleri de aramıza bekliyoruz.
        </p>
      </section>
    </div>
  );
}

export default EtkinliklerPage;
