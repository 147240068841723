import React, { useState, useEffect } from 'react';
import AnnouncementForm from './AnnouncementForm';
import AnnouncementList from './AnnouncementList';
import MembersList from './MembersList';  // Üyeler bileşeni
import UsersList from './UsersList';  // Yönetici listesi bileşeni
import '../styles/AdminPanel.css';

function AdminPanel({ isLoggedIn, userRole }) {  // isLoggedIn ve userRole prop olarak alınıyor
  const [view, setView] = useState('menu');
  const [announcements, setAnnouncements] = useState([]);
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);  // Sayfa yukarı kaydırılır
  }, []);

  useEffect(() => {
    fetch('http://localhost:9000/api/duyurular')
      .then(response => response.json())
      .then(data => setAnnouncements(data))
      .catch(error => console.error('Duyurular alınırken hata oluştu:', error));
  }, []);

  const handleEdit = (announcement) => {
    setSelectedAnnouncement(announcement);
    setView('form');
  };

  const handleDelete = (id) => {
    fetch(`http://localhost:9000/api/duyurular/${id}`, { method: 'DELETE' })
      .then(() => {
        setAnnouncements(announcements.filter(announcement => announcement.id !== id));
      })
      .catch(error => console.error('Duyuru silinirken hata oluştu:', error));
  };

  const handleFormSubmit = (formData) => {
    fetch('http://localhost:9000/api/duyurular', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(addedAnnouncement => {
        setAnnouncements([...announcements, addedAnnouncement]);
        setView('menu');
      })
      .catch(error => console.error('Duyuru eklenirken hata oluştu:', error));
  };

  const handleAddClick = () => {
    setSelectedAnnouncement(null);
    setView('form');
  };

  const handleViewListClick = () => {
    setView('list');
  };

  const handleViewMessagesClick = () => {
    fetch('http://localhost:9000/api/messages')
      .then(response => response.json())
      .then(data => setMessages(data))
      .catch(error => console.error('Mesajlar alınırken hata oluştu:', error));
    setView('messages');
  };

  const handleDeleteMessage = (id) => {
    fetch(`http://localhost:9000/api/messages/${id}`, { method: 'DELETE' })
      .then(() => {
        setMessages(messages.filter(message => message.id !== id));
      })
      .catch(error => console.error('Mesaj silinirken hata oluştu:', error));
  };

  const handleViewMembersClick = () => {
    fetch('http://localhost:9000/api/members')
      .then(response => response.json())
      .then(data => setMembers(data))
      .catch(error => console.error('Üyeler alınırken hata oluştu:', error));
    setView('members');
  };

  const handleDeleteMember = (id) => {
    fetch(`http://localhost:9000/api/members/${id}`, { method: 'DELETE' })
      .then(() => {
        setMembers(members.filter(member => member.id !== id));
      })
      .catch(error => console.error('Üye silinirken hata oluştu:', error));
  };

  const handleBackToMenu = () => {
    setView('menu');
  };

  const handleViewUsersClick = () => {
    setView('users');
  };

  return (
    <div className="admin-panel">
      {view === 'menu' && (
        <div className="button-group">
          <button onClick={handleViewListClick} className="admin-btn">Mevcut Duyuruları Gör</button>
          <button onClick={handleAddClick} className="admin-btn">Yeni Duyuru Ekle</button>
          <button onClick={handleViewMessagesClick} className="admin-btn">İletişim Mesajlarını Gör</button>
          <button onClick={handleViewMembersClick} className="admin-btn">Kaydolan Üyeleri Görüntüle</button>
          {isLoggedIn && userRole === 'super-user' && (
            <button onClick={handleViewUsersClick} className="admin-btn">Yönetici Düzenle</button>
          )}
        </div>
      )}
      {view === 'list' && (
        <>
          <AnnouncementList
            announcements={announcements}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
      {view === 'form' && (
        <>
          <AnnouncementForm
            announcement={selectedAnnouncement}
            onSave={handleFormSubmit}
            onCancel={handleBackToMenu}
          />
        </>
      )}
      {view === 'messages' && (
        <div>
          <h2>İletişim Mesajları</h2>
          <table>
            <thead>
              <tr>
                <th>Ad</th>
                <th>Soyad</th>
                <th>E-posta</th>
                <th>Telefon</th>
                <th>Mesaj</th>
                <th>Tarih</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message.id}>
                  <td>{message.name}</td>
                  <td>{message.surname}</td>
                  <td>{message.email}</td>
                  <td>{message.phone}</td>
                  <td>{message.message}</td>
                  <td>{new Date(message.date).toLocaleString()}</td>
                  <td>
                    <button onClick={() => handleDeleteMessage(message.id)}>Sil</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </div>
      )}
      {view === 'members' && (
        <>
          <MembersList members={members} onDelete={handleDeleteMember} />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
      {view === 'users' && (
        <>
          <UsersList />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
    </div>
  );
}

export default AdminPanel;
