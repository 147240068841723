import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css'; // CSS dosyasını içe aktar

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // JWT'yi manuel çözümlemek için parseJwt fonksiyonu
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // API ile login işlemi
    try {
      const response = await fetch('http://localhost:9000/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, password })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Token'ı localStorage'a kaydediyoruz
        
        // Token'dan rol bilgisini alalım
        const decodedToken = parseJwt(data.token);
        onLogin(decodedToken.role); // Kullanıcının rolünü App'e gönderiyoruz
        navigate('/AdminPaneli'); // Başarılı girişte admin paneline yönlendir
      } else {
        setErrorMessage('Geçersiz kullanıcı adı veya şifre');
      }
    } catch (error) {
      setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Please Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              required
            />
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button type="submit" className="submit-btn">Sign In</button>
        </form>
      </div>
      <div className="triangles-container">
        <span className="triangle triangle-1"></span>
        <span className="triangle triangle-2"></span>
        <span className="triangle triangle-3"></span>
        <span className="triangle triangle-4"></span>
      </div>
    </div>
  );
}

export default Login;
