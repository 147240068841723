import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import AcmPage from './components/AcmPage';
import Duyurular from './components/Duyurular';
import AdminPanel from './components/AdminPanel';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Signup from './components/Signup';
import EtkinliklerPage from './components/EtkinliklerPage';
import Login from './components/Login';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(''); // userRole state'i ekledik

  // JWT parse fonksiyonunu ekledik
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1])); // JWT'nin payload kısmını çöz
    } catch (e) {
      return null;
    }
  };

  // Token kontrolü
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true); // Token varsa kullanıcı giriş yapmış sayılacak
      const decodedToken = parseJwt(token); // parseJwt fonksiyonu ile token'ı çöz
      if (decodedToken) {
        setUserRole(decodedToken.role); // Kullanıcının rolünü alıyoruz
      }
    }

  }, []);

  const addAnnouncement = (newAnnouncement) => {
    setAnnouncements([...announcements, newAnnouncement]);
  };

  const handleLogin = (role) => {
    setIsLoggedIn(true);
    setUserRole(role); // Giriş yapan kullanıcının rolünü set ediyoruz
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token'); // Çıkış yaparken token'ı sil
  };

  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/acm" element={<AcmPage />} />
            <Route
              path="/duyurular"
              element={<Duyurular announcements={announcements} />}
            />
            <Route path="/etkinlikler" element={<EtkinliklerPage />} />
            <Route
              path="/AdminPaneli"
              element={isLoggedIn ? <AdminPanel isLoggedIn={isLoggedIn} userRole={userRole} addAnnouncement={addAnnouncement} /> : <Navigate to="/giris" />}
            />
            <Route path="/iletisim" element={<Contact />} />
            <Route path="/uye-ol" element={<Signup />} />
            <Route path="/giris" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
