import React from 'react';
import { Link } from 'react-router-dom'; // Link bileşenini import ediyoruz
import '../styles/header.css';
import logo from '../styles/acm-logoo.png';

function Header() {
  return (
    <header>
      <img src={logo} alt="ACM Iğdır Logo" />
      <nav>
        <ul>

          <li><Link to="/" className="nav-link">Ana Sayfa</Link></li>

          <li><Link to="/acm" className="nav-link">ACM Nedir?</Link></li>
          <li><Link to="/etkinlikler" className="nav-link">Etkinlikler</Link></li>
          <li><Link to="/iletisim" className="nav-link">İletişim</Link></li>
          <li><Link to="/duyurular" className="nav-link">Duyurular</Link></li>
          <li><Link to="/uye-ol" className="register-btn">Üye Ol</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
