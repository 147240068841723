import '../styles/acmPage.css'; // Stil dosyasını dahil et
import React, { useEffect } from 'react';

function AcmPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="acm-page">
      {/* ACM Nedir Bölümü */}
      <section className="section">
        <h2 className="acm-subtitle">ACM NEDİR¿</h2>

        <img src={require('../styles/acm.png')} alt="ACM Logo" className="acm-logo" />
        <h1 className="section-title">ACM Nedir?</h1>
        <div className="divider"></div>
        <p>
          ACM (Association for Computing Machinery), bilgisayar mühendisliği bilimi ve uygulamalarını bir araya getiren 1947'de kurulan uluslararası bir organizasyondur. ACM, dünyanın en büyük bilimsel ve eğitimsel bilgisayar topluluğudur.
        </p>
      </section>

      {/* ACM Iğdır Öğrenci Topluluğu Bölümü */}
      <section className="section">
        <h1 className="section-title">ACM Iğdır Öğrenci Topluluğu</h1>
        <div className="divider"></div>

        <p>
          ACM Iğdır Öğrenci Topluluğu, teknoloji ve bilgisayar bilimleriyle ilgilenen öğrencilere yönelik çeşitli etkinlikler ve projeler sunan bir topluluktur. Topluluğumuz, öğrencilerin hem sosyal hem de teknik becerilerini geliştirmeyi hedefler.
        </p>
      </section>

      {/* Neden ACM Iğdır'a Katılmalıyım? */}
      <section className="section">
        <h1 className="section-title">Neden ACM Iğdır'a Katılmalıyım?</h1>
        <div className="divider"></div>

        <p>
          Teknolojiye meraklıysanız, bilgisayar bilimleri alanında kendinizi geliştirmek istiyorsanız ve aynı zamanda harika bir sosyal çevre oluşturmak istiyorsanız, ACM Iğdır tam size göre! Etkinliklerimizde ve projelerimizde yer alarak, hem kariyerinize katkıda bulunacak hem de yeni arkadaşlıklar kuracaksınız.
        </p>
      </section>
    </div>
  );
}

export default AcmPage;
