import React, { useEffect, useState } from 'react';

function UsersList({ onBack }) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('http://localhost:9000/api/users', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Kullanıcılar alınamadı');
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  const handleEdit = (user) => {
    // Kullanıcı düzenleme işlemi burada olacak
    console.log('Edit user:', user);
    // Düzenleme işlemi için ilgili callback burada kullanılabilir.
  };

  const handleDelete = (userId) => {
    // Kullanıcı silme işlemi burada olacak
    console.log('Delete user with ID:', userId);
    // Silme işlemi için ilgili callback burada kullanılabilir.
  };

  if (error) {
    return (
      <div className="users-list">
        <p>Hata: {error}</p>
      </div>
    );
  }

  return (
    <div className="users-list">
      <h2>Kullanıcı Listesi</h2>
      {users.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Kullanıcı Adı</th>
              <th>Rol</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>
                  <button onClick={() => handleEdit(user)}>Düzenle</button>
                  <button onClick={() => handleDelete(user.id)}>Sil</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Kullanıcı yok.</p>
      )}
      <button className="back-btn" onClick={onBack}>Geri Dön</button>
    </div>
  );
}

export default UsersList;
