import React from 'react';
import '../styles/AnnouncementList.css';

function AnnouncementList({ announcements = [], onEdit, onDelete }) {
  return (
    <div className="announcement-list">
      <h2>Mevcut Duyurular</h2>
      <div className="announcement-cards">
        {announcements.length > 0 ? (
          announcements.map((announcement, index) => (
            <div key={index} className="announcement-card">
              <img src={`http://localhost:9000/assets/${announcement.cardImage}`} alt={announcement.title} className="announcement-image" />
              <div className="announcement-info">
                <h3>{announcement.title}</h3>
                <p>{announcement.date}</p>
                <div className="announcement-actions">
                  <button onClick={() => onEdit(announcement)} className="edit-btn">Düzenle</button>
                  <button onClick={() => onDelete(announcement.id)} className="delete-btn">Sil</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Görüntülenecek duyuru yok.</p>
        )}
      </div>
    </div>
  );
}

export default AnnouncementList;
