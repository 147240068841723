import React, { useState, useEffect } from 'react';
import '../styles/MembersList.css'; // Üyelik listesi için stil dosyası

function MembersList() {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetch('http://localhost:9000/api/members')
      .then(response => response.json())
      .then(data => setMembers(data))
      .catch(error => console.error('Üyeler alınırken hata oluştu:', error));
  }, []);

  return (
    <div className="members-list">
      <h2>Kaydolan Üyeler</h2>
      <table>
        <thead>
          <tr>
            <th>Ad Soyad</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>Bölüm</th>
            <th>Sınıf</th>
            <th>Okul Numarası</th>
            <th>Beklentiler</th>
            <th>Nasıl Haberdar Oldunuz</th>
          </tr>
        </thead>
        <tbody>
          {members.length > 0 ? (
            members.map(member => (
              <tr key={member.id}>
                <td>{member.fullName}</td>
                <td>{member.email}</td>
                <td>{member.phone}</td>
                <td>{member.department}</td>
                <td>{member.class}</td>
                <td>{member.schoolNumber}</td>
                <td>{member.expectations}</td>
                <td>{member.referral}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Henüz üye kaydedilmedi.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MembersList;
